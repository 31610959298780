// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-achievements-tsx": () => import("./../../../src/pages/achievements.tsx" /* webpackChunkName: "component---src-pages-achievements-tsx" */),
  "component---src-pages-c-compound-101-tsx": () => import("./../../../src/pages/c/compound101.tsx" /* webpackChunkName: "component---src-pages-c-compound-101-tsx" */),
  "component---src-pages-c-ethereum-101-tsx": () => import("./../../../src/pages/c/ethereum101.tsx" /* webpackChunkName: "component---src-pages-c-ethereum-101-tsx" */),
  "component---src-pages-c-index-tsx": () => import("./../../../src/pages/c/index.tsx" /* webpackChunkName: "component---src-pages-c-index-tsx" */),
  "component---src-pages-curriculum-tsx": () => import("./../../../src/pages/curriculum.tsx" /* webpackChunkName: "component---src-pages-curriculum-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

